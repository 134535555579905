<template>
  <v-container fluid class="mb-16">
    <v-card height="100%">
      <v-row>
        <v-card-text style="background: white; height: 100%">
          <v-row class="ml-7" style="width: 96%">
            <v-col>
              <template>
                <v-card>
                  <v-card-title>
                    Merchant Specifications
                    <v-spacer></v-spacer>
                  </v-card-title>

                  <v-row>
                    <v-col cols="6">
                      <v-tabs
                        v-model="tab"
                        background-color="transparent"
                        color="primary"
                        grow
                      >
                        <v-tab v-for="item in items" :key="item">
                          {{ item }}
                        </v-tab>
                      </v-tabs>
                    </v-col>
                  </v-row>

                  <v-tabs-items v-model="tab">
                    <v-tab-item>
                      <v-data-table
                        :headers="allHeader"
                        :items="orders"
                        :search="searchseller"
                        class="elevation-1"
                        style="background: #f5f5f5"
                        :loading="loading"
                        loading-text="Loading... Please wait"
                        no-data-text="No products available"
                      >
                        <template v-slot:top>
                          <v-card-title style="font-size: 26px">
                            <v-select
                              :items="merchants"
                              label="Select Merchant"
                              item-text="username"
                              @change="onMerchantChange('all')"
                              return-object
                              v-model="selected_merchant"
                              style="width: 100px"
                            ></v-select>
                            <v-spacer></v-spacer>
                            <div class="pr-5 pb-5 pt-2">
                              <v-text-field
                                v-model="searchseller"
                                append-icon="mdi-magnify"
                                label="Search"
                                filled
                                rounded
                                dense
                                hide-details
                              ></v-text-field>
                            </div>
                          </v-card-title>
                        </template>

                        <template v-slot:item.adminaction="{ item }">
                          <!-- v-if="item.product_admin_status == 'Processing'" -->
                          <div>
                            <v-icon
                              color="primary"
                              class="pa-2 mx-4"
                              @click="Approve(item)"
                              >mdi-checkbox-marked-circle</v-icon
                            >

                            <v-icon
                              color="red"
                              class="pa-2"
                              @click="ApproveCancel(item)"
                              >mdi-close-circle</v-icon
                            >
                          </div>
                        </template>

                        <template v-slot:item.actions="{ item }">
                          <v-btn
                            color="primary"
                            style="padding-left: 0px !important"
                            text
                            @click="viewDetail(item)"
                            >Detail</v-btn
                          >
                        </template>
                      </v-data-table>
                    </v-tab-item>

                    <v-tab-item>
                      <v-data-table
                        :headers="pendingHeader"
                        :items="pendingp"
                        :search="searchapprove"
                        class="elevation-1"
                        style="background: #f5f5f5"
                        :loading="loading"
                        loading-text="Loading... Please wait"
                      >
                        <template v-slot:top>
                          <v-card-title style="font-size: 26px">
                            <v-select
                              :items="merchants"
                              label="Select Merchant"
                              item-text="username"
                              @change="onMerchantChange('pendig')"
                              return-object
                              v-model="selected_merchant_pending"
                              style="width: 100px"
                            ></v-select>
                            <v-spacer></v-spacer>
                            <div class="pr-5 pb-5 pt-2">
                              <v-text-field
                                v-model="searchapprove"
                                append-icon="mdi-magnify"
                                label="Search"
                                filled
                                rounded
                                dense
                                hide-details
                              ></v-text-field>
                            </div>
                          </v-card-title>
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-btn
                            color="primary"
                            style="padding-left: 0px !important"
                            text
                            @click="onApprove(item)"
                            >Approve</v-btn
                          >
                        </template>
                      </v-data-table>
                    </v-tab-item>

                    <v-tab-item>
                      <v-data-table
                        :headers="allHeader"
                        :items="approvep"
                        :search="searchpending"
                        sort-by="id"
                        class="elevation-1"
                        style="background: #f5f5f5"
                        :loading="loading"
                        loading-text="Loading... Please wait"
                      >
                        <template v-slot:top>
                          <v-card-title style="font-size: 26px">
                            <v-select
                              :items="merchants"
                              label="Select Merchant"
                              item-text="username"
                              @change="onMerchantChange('approved')"
                              return-object
                              v-model="selected_merchant_approved"
                              style="width: 100px"
                            ></v-select>
                            <v-spacer></v-spacer>
                            <div class="pr-5 pb-5 pt-2">
                              <v-text-field
                                v-model="searchpending"
                                append-icon="mdi-magnify"
                                label="Search"
                                filled
                                rounded
                                dense
                                hide-details
                              ></v-text-field>
                            </div>
                          </v-card-title>
                        </template>

                        <template v-slot:item.actions="{ item }">
                          <v-btn
                            color="primary"
                            style="padding-left: 0px !important"
                            text
                            @click="viewDetail(item)"
                            >Detail</v-btn
                          >
                        </template>
                      </v-data-table>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card>
              </template>
            </v-col>
          </v-row>

          <v-dialog
            v-model="dialog"
            transition="scale-transition"
            origin="top right"
            :nudge-left="500"
            max-width="900px"
            max-height="650"
          >
            <v-card class="pa-5">
              <v-card class="pa-5" elevation="0">
                <v-app-bar absolute color="white" flat>
                  <v-toolbar-title class="font-weight-bold"
                    >Products Detail</v-toolbar-title
                  >

                  <v-spacer></v-spacer>
                  <v-btn
                    class=""
                    icon
                    elevation="0"
                    @click="closedialog"
                    color="red"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-app-bar>

                <v-card
                  class="mx-auto mt-12"
                  elevation="0"
                  v-if="
                    !(
                      Object.keys(sellerPdetails).length === 0 &&
                      sellerPdetails.constructor === Object
                    )
                  "
                >
                  <v-row>
                    <v-carousel
                      cycle
                      height="100%"
                      hide-delimiter-background
                      show-arrows-on-hover
                      v-if="sellerPdetails.images.length > 0"
                    >
                      <v-carousel-item
                        v-for="(slide, i) in sellerPdetails.images"
                        :key="i"
                        :src="slide.image_url"
                      >
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center"
                        >
                        </v-row>
                      </v-carousel-item>
                    </v-carousel>
                  </v-row>

                  <v-card-text>
                    <v-row align="center">
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Product ID :
                          <span class="font-weight-regular">{{
                            sellerPdetails.id
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Merchant Name :
                          <span class="font-weight-regular">{{
                            sellerPdetails.seller_name
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="12"
                        ><p class="font-weight-medium">
                          Product Title :
                          <span class="font-weight-regular">{{
                            sellerPdetails.title
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="12">
                        <p class="font-weight-medium">
                          Category :
                          <span class="font-weight-regular">{{
                            sellerPdetails.category
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Sub Category :
                          <span class="font-weight-regular">{{
                            sellerPdetails.sub_category
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Sub Sub Category :
                          <span class="font-weight-regular">{{
                            sellerPdetails.sub_sub_category
                          }}</span>
                        </p>
                      </v-col>

                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Brand :
                          <span class="font-weight-regular"
                            >{{ sellerPdetails.brand }}
                          </span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Warrenty :
                          <span class="font-weight-regular">{{
                            sellerPdetails.warranty
                          }}</span>
                        </p>
                      </v-col>

                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Quantity :
                          <span class="font-weight-regular">{{
                            sellerPdetails.quantity
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6"> Unit : {{ sellerPdetails.unit }} </v-col>

                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Origin :
                          <span class="font-weight-regular">{{
                            sellerPdetails.origin
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6"
                        ><p class="font-weight-medium">
                          Shipping Country :
                          <span class="font-weight-regular">{{
                            sellerPdetails.shipping_country
                          }}</span>
                        </p>
                      </v-col>

                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Point Validity Till :
                          <span>{{ sellerPdetails.point_end_date }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Properties : <span></span
                          >{{ sellerPdetails.properties }}
                        </p>
                      </v-col>
                      <v-col cols="12">
                        <p class="font-weight-medium">Product Description</p>
                        {{ sellerPdetails.description }}
                      </v-col>

                      <v-col cols="12">
                        <div>
                          <p class="font-weight-medium">Key Features :</p>

                          <li
                            class="mt-5"
                            v-for="(
                              itemfeatures, index
                            ) in sellerPdetails.key_features"
                            :key="index"
                          >
                            {{ itemfeatures }}
                          </li>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-card>
            </v-card>
          </v-dialog>
        </v-card-text>
      </v-row>
    </v-card>
    <v-snackbar v-model="snackbar" centered>
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>



<script>
  export default {
    data: () => ({
      dialog: false,
      search: "",
      editdialog: false,
      loading: true,
      searchseller: "",
      searchapprove: "",
      searchpending: "",
      CancelledSearch: "",
      approvep: [],
      pendingp: [],
      Cancelp: [],

      allHeader: [
        {
          text: "Merchant Email",
          align: "start",
          value: "seller_email",
          sortable: false,
        },
        { text: "SKU", value: "SKU", sortable: false },
        { text: "Product Name", value: "product_name", sortable: false },
        { text: "Brand ", value: "product_brand" },
        { text: "Price", value: "purchase_price", sortable: false },
        { text: "color", value: "color" },
        { text: "size", value: "size" },
        { text: "weight", value: "weight" },
        { text: "variant", value: "weight_unit" },
        { text: "Commision Amount", value: "commission_amount" },
        { text: "Commision value", value: "commission_value" },
        { text: "Commision Type", value: "commission_type" },
        { text: "Selling Price", value: "selling_price" },
        { text: "MRP", value: "MRP" },

        { text: "", value: "data-table-expand" },
      ],
      pendingHeader: [
        {
          text: "Merchant Email",
          align: "start",
          value: "seller_email",
          sortable: false,
        },
        { text: "SKU", value: "SKU", sortable: false },
        { text: "Product Name", value: "product_name", sortable: false },
        { text: "Brand ", value: "product_brand" },
        { text: "Price", value: "purchase_price", sortable: false },
        { text: "color", value: "color" },
        { text: "size", value: "size" },
        { text: "weight", value: "weight" },
        { text: "variant", value: "weight_unit" },
        { text: "Commision Amount", value: "commission_amount" },
        { text: "Commision value", value: "commission_value" },
        { text: "Commision Type", value: "commission_type" },
        { text: "Selling Price", value: "selling_price" },
        { text: "MRP", value: "MRP" },
        { text: "", value: "data-table-expand" },
        { text: "Action", value: "actions" },
      ],
      spac_header: [
        {
          text: "Size",
          align: "start",
          value: "size",
          sortable: false,
        },
        { text: "Color", value: "color", sortable: false },
        { text: "Unit ", value: "unit", sortable: false },
        { text: "Weight ", value: "weight", sortable: false },
        { text: "Purchase Price ", value: "purchase_price", sortable: false },
        { text: "Selling Price ", value: "new_price", sortable: false },
      ],
      ApproveHeader: [
        {
          text: "Merchant Email",
          align: "start",
          value: "seller_email",
          sortable: false,
        },
        { text: "Product Title", value: "title", sortable: false },
        { text: "Brand ", value: "brand", sortable: false },
        { text: "Total Quantity", value: "total_quantity", sortable: false },
        { text: "Menufectured In ", value: "unit_price", sortable: false },
        { text: "Shiping From", value: "product_price", sortable: false },
        { text: "Commision Amount", value: "commission_amount" },
        { text: "Commision value", value: "commission_value" },
        { text: "Commision Type", value: "commission_type" },
        { text: "Selling Price", value: "selling_price" },
        { text: "MRP", value: "MRP" },
        { text: "View", value: "actions", sortable: false },
        //{ text: "Approve", value: "adminaction", sortable: false },
        // { text: 'test', value: 'tes' },
        // { text: "", value: "data-table-expand" },
      ],
      CancelledHeader: [
        {
          text: "Merchant Email",
          align: "start",
          value: "seller_email",
          sortable: false,
        },
        { text: "SKU", value: "SKU", sortable: false },
        { text: "Product Name", value: "title", sortable: false },
        { text: "Brand ", value: "product_brand" },
        { text: "Purchase price", value: "purchase_price", sortable: false },
        { text: "color", value: "color" },
        { text: "size", value: "size" },
        { text: "weight", value: "weight" },
        { text: "variant", value: "weight_unit" },
        { text: "Commision Amount", value: "commission_amount" },
        { text: "Commision value", value: "commission_value" },
        { text: "Commision Type", value: "commission_type" },
        { text: "Selling Price", value: "selling_price" },
        { text: "MRP", value: "MRP" },
        { text: "", value: "data-table-expand" },
      ],
      tab: null,
      items: ["All", "Pending", " Approved"],
      tickets: [],
      contact: {},

      orders: [],

      sellerPdetails: {},
      snackbar: false,
      text: "",
      merchants: [],
      selected_merchant: {},
      selected_merchant_pending: {},
      selected_merchant_approved: {},
    }),

    methods: {
      initialize() {
        axios.get("merchant/all_merchant_all_products").then((response) => {
          this.loading = false;
          if (response.data.success) {
            this.orders = response.data.data;
          } else {
            this.orders = [];
          }
        });
      },

      onMerchantChange(type) {
        if (type == "all") {
          axios
            .get(
              `merchant/get_merchant_all_Specification/${this.selected_merchant.id}/`
            )
            .then((response) => {
              if (response.data.success) {
                this.orders = response.data.data;
              } else {
                this.orders = [];
              }
            })
            .catch((err) => {
              this.orders = [];
            });
        } else if (type == "pending") {
          axios
            .get(
              `merchant/get_merchant_pending_Specification/${this.selected_merchant_pending.id}/`
            )
            .then((response) => {
              if (response.data.success) {
                this.pendingp = response.data.data;
              } else {
                this.pendingp = [];
              }
            })
            .catch((err) => {
              this.pendingp = [];
            });
        } else {
          axios
            .get(
              `merchant/get_merchant_approved_Specification/${this.selected_merchant_approved.id}/`
            )
            .then((response) => {
              if (response.data.success) {
                this.approvep = response.data.data;
              } else {
                this.approvep = [];
              }
            });
        }
      },
      getpendingproduct() {
        axios
          .get("merchant/all_merchant_pending_products")
          .then((response) => {
            this.loading = false;
            if (response.data.success) {
              this.pendingp = response.data.data;
            } else {
              this.pendingp = [];
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      },
      getapproveproduct() {
        axios.get("merchant/all_merchant_approved_products").then((response) => {
          this.loading = false;
          if (response.data.success) {
            this.approvep = response.data.data;
          } else {
            this.approvep = [];
          }
        });
      },
      getcalcelledproduct() {
        axios
          .get("productdetails/admin_merchant_cancelled_products")
          .then((response) => {
            if (response.data.success) {
              this.Cancelp = response.data.data;

              this.loading = false;
            }
          });
      },

      closedialog() {
        this.dialog = false;
      },

      viewDetail(item) {
        this.sellerPdetails = item;
        this.dialog = true;
      },

      onApprove(item) {
        axios
          .get(`merchant/publish_unpublish_spec/${item.id}`)
          .then((response) => {
            if (response.data.success) {
              this.createSnackbar("Specification approved successfully");
              this.getpendingproduct();
            } else {
              this.createSnackbar("Something went wrong");
            }
          })
          .catch((err) => {
            this.createSnackbar("Something went wrong");
          });
      },

      Approve(item) {
        // open a new tab for updating quanttiy and approving
        let routeData = this.$router.resolve({
          name: `/dashboard/pages/approve-products/${item.id}`,
        });
        routeData.href = `/dashboard/pages/approve-products/?id=${item.id}`;
        window.open(routeData.href, "_blank");

        // if (confirm("Do you want to Approve ?")) {
        //   axios
        //     .post(`product/merchent_approval/${item.id}/`, {
        //       admin_status: "Confirmed",
        //     })
        //     .then((response) => {
        //       if (response.data.success) {
        //         let index = this.orders.findIndex(
        //           (product) => product.id == item.id
        //         );
        //         this.orders[index].product_admin_status = "Confirmed";
        //       }
        //     })
        //     .catch((err) => {
        //       alert("Something went wrong");
        //     });
        // }
      },

      ApproveCancel(item) {
        if (confirm("Do you really want to Cancel Order ?")) {
          axios
            .post(`product/merchant_calcceled/${item.id}/`, {
              admin_status: "Cancelled",
            })
            .then((response) => {
              // let index = this.orders.findIndex(
              //   (product) => product.id == item.id
              // );
              // this.orders[index].product_admin_status = "Cancelled";
              this.orders = this.orders.filter(
                (product) => product.id != item.id
              );
            })
            .catch((err) => {
              this.text = "Something went wrong";
              this.snackbar = true;
            });
        }
      },

      createSnackbar(text) {
        this.text = text;
        this.snackbar = true;
      },
    },

    mounted() {
      this.initialize();
      this.getpendingproduct();
      this.getcalcelledproduct();
      this.getapproveproduct();

      axios
        .get("user/show_all_merchants/")
        .then((response) => {
          this.merchants = response.data.data;
        })
        .catch((err) => {
          this.merchants = [];
        });
    },
  };
</script>



<style >
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 1.2rem;
    height: 48px;
  }
</style>
